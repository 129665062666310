<template>
    <div class="auth-content">
        <template v-if="isResorePage">
            <form @submit.prevent="codeSubmit" >
                <div class="es-input-cover">
                    <div class="es-input-cover">
                        <input type="email"
                               required
                               class="es-input"
                               v-model="mail"
                               placeholder="Ваша почта"
                        />
                    </div>
                    <input type="tel"
                           required
                           maxlength="4"
                           class="es-input"
                           v-model="code"
                           placeholder="Введите код"
                    />
                </div>
                <button type="submit" class="es-button es-button--blue">
                    Готово
                </button>
            </form>
        </template>
        <template v-else>
            <div class="auth-content__logout-info " v-if="isLogouted">
                В личный кабинет можно одновременно
                заходить с одного устройства или
                браузера. Войдите повторно.
            </div>
            <div class="auth-content__descr" v-if="!isSended">
                Если вы раньше делали покупки,
                введите свой email и вам на почту
                придет короткий код подтверджения.
            </div>
            <div class="auth-content__success-text" v-if="isSended">
                Код выслан на почту {{mail}} (проверьте папку Спам).
                Введите его, чтобы попасть в личный кабинет.

                <form class="auth-content__code-form" @submit.prevent="codeSubmit">
                    <div class="es-input-cover">
                        <input type="tel"
                               required
                               maxlength="4"
                               class="es-input"
                               v-model="code"
                               placeholder="Введите код"
                        />
                    </div>
                    <button type="submit" class="es-button es-button--blue">
                        Готово
                    </button>
                </form>
            </div>
            <form class="auth" @submit.prevent="submit" v-else>
                <div class="es-input-cover">
                    <input type="email"
                           required
                           class="es-input"
                           v-model="mail"
                           placeholder="Ваша почта"
                    />
                </div>
                <button type="submit" class="es-button es-button--blue">
                    Выслать код на почту
                </button>
                <router-link to="/restore"  class="es-button es-button--link">
                    У меня уже есть код
                </router-link>
            </form>
        </template>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AuthPage',
  components: {},
  data() {
    return {
      mail: '',
      isSended: false,
      isLogouted: false,
    };
  },
  computed: {
    isResorePage() {
      return this.$route.name === 'Restore';
    },
  },
  methods: {
    codeSubmit() {
      axios
        .post(`${this.$store.getters.getLocalUrl}/api/checkRestoreCode.php`, {
          mail: this.mail,
          code: this.code,
        })
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('saveUserMail', response.data.mail);
            this.$store.commit('saveAuthToken', response.data.token);

            this.$store.dispatch('updateMeditations');

            this.$router.push({ name: 'Home' });
          } else {
            this.$store.commit('showGlobalError', response.data.errorText);
          }
        });
    },
    submit() {
      const successMessage = 'Код подтверждения отправлен на почту.';

      axios
        .post(`${this.$store.getters.getLocalUrl}/api/sendRestoreCode.php`, {
          mail: this.mail,
        })
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('showGlobalMessage', successMessage);
            this.isSended = true;
          } else {
            this.$store.commit('showGlobalError', response.data.errorText);
          }
        });
    },
  },
  beforeDestroy() {
    this.$store.commit('showBurger');
  },
  mounted() {
    const url = new URL(window.location.href);
    const logouted = url.searchParams.get('logouted');

    if (logouted) {
      this.isLogouted = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";
@import "../../assets/scss/common/forms";

.auth-content {
    margin: 30px 0;
    font-size: 14px;
    padding: 0 24px;

    &__code-form {
        display: flex;
        flex-direction: row;
        margin: 20px auto;
        max-width: 320px;

        input {
            height: 60px;
            text-align: center;
            font-size: 22px;

            &::placeholder {
                font-size: 16px;
            }
        }

        button {
            height: 60px;
            width: 100px;
            margin-left: 15px;
        }
    }

    &__logout-info {
        margin: 15px 0 30px 0;
        font-family: $textFont;
        line-height: 26px;
        font-size: 18px;
    }

    &__descr {
        margin: 15px 0 30px 0;
        font-family: $textFont;
        line-height: 20px;
    }

    &__success-text {
        margin: 15px 0 30px 0;
        font-family: $textFont;
        text-align: center;
        line-height: 21px;
        font-weight: 600;

        form.auth {
            display: block;
            margin-top: 25px;
        }
    }
}
</style>
